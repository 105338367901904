<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div v-if="(versionType && canAccess('UI_WORKLOAD:VERSION_EDIT')) || (!versionType && canAccess('UI_NODE:EDIT'))">
    <div v-for="(iconAction, i) in iconsAction" :key="i" class="inline">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-img
            :id="`iiotRemoteConnectionAction${iconAction}Image_${index}`"
            :src="`/img/${iconAction}.svg`"
            v-bind="attrs"
            max-height="40px"
            max-width="40px"
            v-on="on"
            @click.native.stop="clickAction()"
          />
        </template>
        <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Logger from '@/utils/logger';

export default {
  props: {
    iconsAction: {
      type: Array,
      default: () => [],
    },
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    versionId: {
      type: String,
      default: '',
    },
    version: {
      type: Object,
      default: () => ({}),
    },
    versionType: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      model: '',
    };
  },

  computed: {
    list() {
      return this.$store.getters['remote-connection/list'];
    },
    nodeModel() {
      return this.$store.getters['nodes/getNodeData'];
    },
  },

  beforeMount() {
    // eslint-disable-next-line prefer-destructuring
    this.model = window.location.pathname.split('/')[1];
  },

  methods: {
    clickAction() {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'remoteConnection.dialog.deleteConfirm',
        subTitle: this.$t('remoteConnection.dialog.removeSubTitle', { name: this.tableItem.name }),
        callback: async () => {
          try {
            if (this.model === 'nodes') {
              this.nodeModel.connection = this.tableItem;
              this.nodeModel.delete = true;
              this.nodeModel.connection.type = this.tableItem.type;
              this.nodeModel.index = this.index;
              await this.$store.dispatch('remote-connection/update_node', {
                nodeData: this.nodeModel,
              });
            } else if (this.model === 'workloads') {
              await this.$store.dispatch('remote-connection/delete_remote_connection', {
                index: this.index,
              });
              await this.$store.dispatch('workloads/removeRCFromVersion', { index: this.index });
              this.$store.dispatch('workloads/form_has_changed');
            }
          } catch (e) {
            Logger.error(e);
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>
